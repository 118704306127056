import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import React, { useLayoutEffect, useRef, useEffect } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import ContentWrapper from '../contentWrapper'
import {
  disableFocusBehindModal,
  enableFocusBehindModal
} from '../../util/modalHelper'

import './article.scss'

function Article({ children, handleClose, animating, origin }) {
  const modalRef = useRef(null)

  useLayoutEffect(() => {
    const modal = modalRef.current;
    disableBodyScroll()
    disableFocusBehindModal(modal)
    // window.addEventListener('popstate', handlePopState)
    return () => {
      enableBodyScroll()
      enableFocusBehindModal(modal)
      // window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  useEffect(() => {
    const handleOutsideClick = e => {
      if (modalRef.current !== e.target && modalRef.current.contains(e.target)) {
        return
      }
      handleClose()
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClose])

  // function handlePopState() {
  //  if(window.location.hash === '') {
  //    handleClose();
  //  }
  // }

  return (
    <article
      ref={modalRef}
      className={
        animating
          ? `article-overlay article-overlay--animating`
          : `article-overlay`
      }
    >
      <div
        style={{ transformOrigin: `${origin.x}px ${origin.y}px` }}
        className="article-overlay__inner text"
      >
        <ContentWrapper>
          <a
            href="/"
            className="button button--invert button--light-shadow button--icon button--icon-l button--icon-blue article-overlay__close"
            onClick={handleClose}
          >
            <FiArrowLeft />
            Tillbaka
          </a>
        </ContentWrapper>

        {children}
      </div>
    </article>
  )
}
export default Article
