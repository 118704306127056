import React, { useState, useEffect, useRef } from 'react'
import TextImage from '../../components/textImage'
import Article from '../../components/article'
import { GoPlus } from 'react-icons/go'
import { FiArrowRight } from 'react-icons/fi'
import ContentWrapper from '../../components/contentWrapper'
import { navigate } from 'gatsby'

function Feature({ items }) {
  

  const [openedArticleUid, setOpenedArticleUid] = useState()
  const [animating, setAnimating] = useState(false)
  const [origin, setOrigin] = useState({})
  const [loaded, setLoaded] = useState(false)
  const buttonRef = useRef([])
  const wrapperRef = useRef([])



  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', handlePopState)
      return () => {
        window.removeEventListener('popstate', handlePopState)
      }
    }
  }, [])

  useEffect(() => {
    if (!animating) {
      const slug = window.location.hash.replace('#', '')
      if (slug !== '') {
        if (loaded) {
          setOpenedArticleUid(slug)
        } else {
          setTimeout(() => {
            setOpenedArticleUid(slug)
            setLoaded(true)
          }, 300)
        }
      } else {
        setOpenedArticleUid(null)
      }
    }
  }, [animating, loaded])

  if(!items) return null

  function handlePopState(e) {
    // if ('scrollRestoration' in history) {
    //   history.scrollRestoration = 'manual';
    // }

    if (window.location.hash === '') {
      setOpenedArticleUid(null)
    } else {
      const slug = window.location.hash.replace('#', '')
      setOpenedArticleUid(slug)
    }
  }

  function openArticle(slug, i) {
    const buttonRect = buttonRef.current[i].getBoundingClientRect()
    const wrapperRect = wrapperRef.current[i].getBoundingClientRect()

    setOrigin({ x: buttonRect.x - wrapperRect.x, y: buttonRect.y })
    setOpenedArticleUid(slug)
    setAnimating(true)
    document.addEventListener('keydown', handleEscKey)
    navigate(`${window.location.pathname}#${slug}`, {
      state: { scroll: false },
    })
    setTimeout(() => {
      setAnimating(false)
    }, 10)
  }
  function closeOpenedArticle(e) {
    if (e) e.preventDefault()
    setAnimating(true)
    document.removeEventListener('keydown', handleEscKey)
    setTimeout(() => {
      setAnimating(false)
      setOpenedArticleUid(null)
    }, 300)
    navigate(`${window.location.pathname}`, {
      state: { scroll: false },
    })
  }

  function handleEscKey(e) {
    if (e.key === 'Escape') {
      closeOpenedArticle()
    }
  }

  const features = items.map((item, index) => {
    if(!item.heading) {
      return null
    }

    const article = (
      <div key={index}>
        <div ref={el => (buttonRef.current[index] = el)}>
          <button
            className="button button--icon button--with-margin button--md"
            onClick={() => {
              if (item.link) {
                navigate(item.link, { state: { scroll: true } })
              } else {
                openArticle(item.article.slug, index)
              }
            }}
          >
            {item.linkText ? item.linkText : 'Läs mer'}
            {item.link && <FiArrowRight />}
            {!item.link && <GoPlus />}
          </button>
        </div>
        {openedArticleUid === item.article.slug && (
          <Article
            handleClose={closeOpenedArticle}
            animating={animating}
            origin={origin}
          >
            <ContentWrapper>
              <h1 className="article__heading heading-1">
                {item.article.heading}
              </h1>
              {item.article.body}
            </ContentWrapper>
          </Article>
        )}
      </div>
    )

    return (
      <div
        key={index}
        className="feature"
        ref={el => (wrapperRef.current[index] = el)}
      >
        <TextImage title={item.heading} text={item.text} image={item.image}>
          {article}
        </TextImage>
      </div>
    )
  })

  return <div>{features}</div>
}

export default Feature
