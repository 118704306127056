import React from 'react'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import Main from '../components/main'
import ContentWrapper from '../components/contentWrapper'
import '../styles/patterns/largeHeader.scss'
import Breadcrumbs from '../components/breadcrumbs'
import Seo from '../components/seo'
import Img from 'gatsby-image'
import Feature from '../components/feature/feature'

const LandingPage = ({ doc, features, crumbs }) => {

  return (
    <Main>
      <Seo
        title={doc.metaTitle}
        description={doc.metaDescription}
        ogType="website"
        url={doc.path}
      />
      <div
        className={
          doc.topImage ? 'large-header large-header--bg' : 'large-header'
        }
      >
        <div className="large-header__inner">
          <ContentWrapper modifier="wide">
            <div className="flex-center-column">
              <h1 className="heading-1 heading-1--larger heading-1--blue">
                {doc.heading}
              </h1>
              {doc.introduction && (
                <div className="list-text list-text--larger">
                  <p>{doc.introduction}</p>
                </div>
              )}
            </div>
          </ContentWrapper>
        </div>
        {doc.topImage && (
          <ParallaxProvider>
            <div className="large-header__overlay"></div>
            <Parallax
              className="large-header__parallax"
              y={[-20, 20]}
              tagOuter="div"
            >
              <Img
                fluid={doc.topImage}
                placeholderClassName="large-header__bg lazy"
                style={{
                  position: 'absolute',
                  left: 0,
                  top: '-40px',
                  bottom: '-40px',
                  right: 0,
                }}
              />
            </Parallax>
          </ParallaxProvider>
        )}
      </div>
      <ContentWrapper modifier="wide">
        <Breadcrumbs crumbs={crumbs} currentPage={doc.heading} />
        {/* <ParallaxProvider> */}
          <Feature items={features} />
          {/* <Slices slices={doc.body} /> */}
        {/* </ParallaxProvider> */}
      </ContentWrapper>
    </Main>
  )
}

export default LandingPage
