import React from 'react'
// import { Parallax } from 'react-scroll-parallax'
import './textImage.scss'
import Img from 'gatsby-image'

function TextImage({ title, text, image, children }) {
  return (
    <React.Fragment>
      <div className="feature__image">
        {/* <Parallax y={[-5, 5]} tagOuter="div"> */}
          {image && (
            <Img
              fluid={{ ...image, aspectRatio: 1 }}
              placeholderClassName="lazy"
            />
          )}
        {/* </Parallax> */}
      </div>
      <div className="feature__body">
        <h2>{title}</h2>
        {text}
        <div>{children}</div>
      </div>
    </React.Fragment>
  )
}

export default TextImage
