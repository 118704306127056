import React from 'react'
import { graphql } from 'gatsby'
import linkResolver from '../../contentful/linkResolver'
import LandingPage from './landingPage'
import { renderRichText } from '../../contentful/renderRichText'
import localizationFilter from '../../contentful/localizationFilter'

const LandingPageContainer = ({ data, pageContext }) => {
  const { contentfulLandingPage } = data
  const { breadcrumbs, locale } = pageContext

  const landingPage = {
    _doc: contentfulLandingPage,
    locale: contentfulLandingPage.node_locale,
    path: linkResolver(contentfulLandingPage),
    metaTitle: contentfulLandingPage.metaTitle || contentfulLandingPage.heading,
    metaDescription: contentfulLandingPage.metaDescription,
    heading: contentfulLandingPage.heading,
    introduction: contentfulLandingPage.introduction
      ? contentfulLandingPage.introduction.introduction
      : null,
    topImage: contentfulLandingPage.topImage
      ? contentfulLandingPage.topImage.fluid
      : null
  }

  const features = contentfulLandingPage.sections
    ? contentfulLandingPage.sections.filter(localizationFilter).map(section => {
        return {
          heading: section.heading,
          text: section.body ? renderRichText(section.body.json, locale) : null,
          image: section.image ? section.image.fluid : null,
          linkText: section.linkText,
          link:
            section.link && section.link.pageType !== 'Overlay page'
              ? linkResolver(section.link)
              : null,
          article: {
            heading: section.link.heading ? section.link.heading : null,
            introduction: section.link.introduction
              ? section.link.introduction.introduction
              : null,
            body: null,
            // body: !(section.link && section.link.pageType !== 'Overlay page')
            //   ? documentToReactComponents(
            //       section.link.body.json,
            //       richTextRendererOptions
            //     )
            //   : null,
            slug: section.link.slug
          }
        }
      })
    : null

  return (
    <LandingPage doc={landingPage} features={features} crumbs={breadcrumbs} />
  )
}

export default LandingPageContainer

export const pageQuery = graphql`
  query($locale: String!, $slug: String!) {
    contentfulLandingPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      heading
      metaDescription
      metaTitle
      slug
      introduction {
        introduction
      }
      topImage {
        fluid(maxWidth: 2000, maxHeight: 500, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
      sections {
        heading
        node_locale
        enableLocalization
        body {
          json
        }
        image {
          fluid(maxWidth: 380, maxHeight: 420, quality: 80) {
            ...GatsbyContentfulFluid
          }
        }
        linkText
        link {
          # body {
          #   json
          # }
          introduction {
            introduction
          }
          slug
          heading
          pageType
          node_locale
          enableLocalization
        }
      }
    }
  }
`
